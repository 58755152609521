import { t } from '../languages';

import service from "./service.js";

const common = {
  //单文件上传
  uploadFile: (data,isFormData) => {
      return service.longPost({
        url: `/api/v1/file/upload`,
        data,
        isFormData,
        timeout:2
      })
    },
    uploadLogo: (data,isFormData) => {
      return service.longPost({
        url: `/api/v1/log/logo/upload`,
        data,
        isFormData,
        timeout:2
      })
    },

  /* 上传证书图片 */
  uploadFiles:(data,isFormData) => {
      return service.longPost({
        url: '/api/v1/upload/certificate',
        data,
        isFormData,
        timeout:2
      })
  },
  
  checkFileMD5: (url,md5) => {
    return service.get({
      url: `${url}${md5}`,
    })
  },
}
export default common;